import styles from './SearchProvider.module.css'
import Sidebar from "../../../sidebar/sidebar";
import {useSelector} from 'react-redux'
import { MultiSelect } from 'primereact/multiselect';
import axios from 'axios'
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import blob5 from "../../../../subPanel/5blob.png";
import  Select  from "react-select";
function SearchProvider(){
    const authToken=useSelector((state)=>state.auth.access)
    const [providers,setProviders]=useState([])
    const [selectedProvider,setSelectedProvider]=useState([])
    const [providerBlocks,setProviderBlocks]=useState([])
    const [maxList,setMaxList]=useState(1)
    const [page,setPage]=useState(1)
    const [pages,setPages] = useState(Array.from({length: maxList}, (_, i) => i + 1))
    const pages_list =pages.map((x) => ({value:x,label:x}))
    const [pageSelected,setPageSelected] = useState({value:1,label:1})
    const [next,setNext]=useState('')
    const [prev,setPrev]=useState('')
    const colorStyle= {
    
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '30px',
          }),
      
        control: (styles) => ({ ...styles, backgroundColor: "rgb(238,238,238)",
        minHeight: '30px',
        height: '30px',}),
        valueContainer: (provided, state) => ({
            ...provided,
            textOverflow: "ellipsis",
            width:"150px",
            maxWidth: "150px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            display: "flex",
            flexDirection:"row",
            height:"30px",
            maxHeight: "30px"
          })
    }
    function pageListChandler(e){
        setPage(e.value)
    }
    function prevChandler(event){
        if (page!=pages[0]){
         setPage(parseInt(page)-1)
        }
        else{
         return
        }
 
     }
     function nextChandler(event){
        if (page!=pages[pages.length-1]){
         setPage(parseInt(page)+1)
        }
        else{
         return
        }
 
     }

    async function getProviderNames(){
        const request = await axios.get(`${process.env.REACT_APP_DOMAIN}/api/service_provider_names`,{
            headers:{
                'Content-Type':'application/json',
                'Authorization':"Bearer " + String(authToken)
            }
        })
        .then(function(response){
            setProviders(response.data.map((x)=>({"name":x.name,"code":x.name})))
          
           
        })
        .catch(function(error){

        })
        .finally(function(){

        })
    }

    async function getProviders(){
      
        const request = await axios.get(`${process.env.REACT_APP_DOMAIN}/api/service_provider_list?name=${selectedProvider.map((x)=>(x.code))}&&page=${page}`,{
            headers:{
                'Content-Type':'application/json',
                'Authorization':"Bearer " + String(authToken)
            }
        })
        .then(function(response){
            setProviderBlocks(response.data.results)
            setNext(response.data.next)
            setPrev(response.data.prev)
            setPages(Array.from({length:Math.ceil(response.data.count/5) }, (_, i) => i + 1))
          
      
        })
        .catch(function(error){
          
        })
        .finally(function(){

        })
    }

    useEffect(()=>{
        getProviderNames()
        getProviders()
    },[])

    useEffect(()=>{
        getProviders()
    },[selectedProvider])
    useEffect(()=>{
        getProviders()
        setPageSelected({value:page,label:page})
        
    },[page])
    return(
        <div>
            
            <div className={styles.block}>
                <Sidebar />
                <div className={styles.menu}>
                <div className={styles.blobHolder}>
						<img src={blob5} className={styles.firstBlob}></img>
						<img src={blob5} className={styles.secondBlob}></img>
						<div className={styles.blobText}>
							<div className={styles.blobTitle}>
								Edit a new NAMs Provider
							</div>
						</div>
					</div>
                    <MultiSelect placeholder='Select a Provider' className={styles.selector}value={selectedProvider} options={providers} onChange={(e)=>(setSelectedProvider(e.value))} optionLabel="name" display="chip" filter/>
                    <div>
                        {providerBlocks.map(x=><Link to={`/edit_provider/${x.id}`} className={styles.providerBlock}>
                            <div className={styles.name}>
                                <div>{x.name}</div>
                            </div>
                            <div className={styles.created}>
                                    <div style={{'color':'#A6A6A6'}}>Created</div>
                                    <div style={{'color':'#737373','font-weight':'bold'}}>{x.created}</div>
                            </div>
                            <div className={styles.modified}>
                                    <div  style={{'color':'#A6A6A6'}}>Modified</div>
                                    {x.modified!=null?
                                    <div>{x.modified}</div>:
                                    <div style={{'color':'#737373','font-weight':'bold'}}>-</div>
                                    }
                                    
                            </div>
                        </Link>)}
                    </div>
                    <div className={styles.table_elements}>
                <div className={styles.page_of_pages}>Page {page} of {pages[pages.length-1]}</div>
                <div className={styles.listWBut}>
                    <div className={styles.pager}>
                        <Select
                           options={pages_list}
                           value={pageSelected}
                            styles={colorStyle}
                            onChange={pageListChandler}
                             allowSelectAll={false}
                             closeMenuOnSelect={true}
                             hideSelectedOptions={false}
                             menuPlacement="top"
                        />
                    </div>
                    <div className={styles.table_but}>
                    {page!=1 ?
                        <button onClick={prevChandler} className={page!=1 ? styles.next_but : styles.next_but_dis}>Previous</button>:
                        <button  className={page!=1 ? styles.next_but : styles.next_but_dis}>Previous</button>}
                        <button onClick={nextChandler} className={page!=pages[pages.length-1] ? styles.next_but : styles.next_but_dis}>Next</button>
                    </div>
                </div>
            </div>
                </div>
                
            </div>
        </div>
    )
}
export default SearchProvider